import Utils from '@/lin/util/util'

// import bookConfig from './book' // 引入图书管理路由文件
import pluginsConfig from './plugin'
// import webBannerRouter from './webBanner'
// import webQuestionRouter from './question'
// import versionRouter from './version'
// import printerRouter from './printer'
// import exploreRouter from './explore'
// import textRouter from './text'
// import authRouter from './auth'
// import taskRouter from './task'
// import sliceRouter from './slice'
// import personalRouter from './personal'
// eslint-disable-next-line import/no-mutable-exports
let homeRouter = [
  {
    title: 'AOSEED后台',
    type: 'view',
    name: Symbol('about'),
    route: '/about',
    filePath: 'view/about/about.vue',
    inNav: true,  //是否在左侧显示
    icon: 'iconfont icon-iconset0103',
    order: 1,
  },
  {
    title: 'banner信息列表',
    type: 'view',
    name: Symbol('banner'),
    route: '/banner',
    filePath: 'view/banner/banner.vue',
    inNav: true,  //是否在左侧显示
    icon: 'iconfont icon-tushuguanli',
    order: 1,
  },
  {
    title: '添加banner信息列表',
    type: 'view',
    name: Symbol('banner'),
    route: '/banneradd',
    filePath: 'view/banner/banner-add.vue',
    inNav: false,  //是否在左侧显示
    icon: 'iconfont icon-tushuguanli',
    order: 1,
  },
  {
    title: '问答信息列表',
    type: 'view',
    name: Symbol('question'),
    route: '/question',
    filePath: 'view/question/question.vue',
    inNav: true,  //是否在左侧显示
    icon: 'iconfont icon-tushuguanli',
    order: 1,
  },
  {
    title: '添加问答信息列表',
    type: 'view',
    name: Symbol('questionadd'),
    route: '/questionadd',
    filePath: 'view/question/question-add.vue',
    inNav: false,  //是否在左侧显示
    icon: 'iconfont icon-tushuguanli',
    order: 1,
  },
  {
    title: '产品信息列表',
    type: 'view',
    name: Symbol('product'),
    route: '/product',
    filePath: 'view/product/product.vue',
    inNav: true,  //是否在左侧显示
    icon: 'iconfont icon-tushuguanli',
    order: 1,
  },

  {
    title: '添加产品信息列表',
    type: 'view',
    name: Symbol('productadd'),
    route: '/productadd',
    filePath: 'view/product/product-add.vue',
    inNav: false,  //是否在左侧显示
    icon: 'iconfont icon-tushuguanli',
    order: 1,
  },
  {
    title: '邮箱信息列表 ',
    type: 'view',
    name: Symbol('mailbox'),
    route: '/mailbox',
    filePath: 'view/mailbox/mailbox.vue',
    inNav: true,  //是否在左侧显示
    icon: 'iconfont icon-tushuguanli',
    order: 1,
  },
  {
    title: '模型列表 ',
    type: 'view',
    name: Symbol('model'),
    route: '/model',
    filePath: 'view/model/model.vue',
    inNav: true,  //是否在左侧显示
    icon: 'iconfont icon-tushuguanli',
    order: 1,
  },
  {
    title: '删除模型列表',
    type: 'view',
    name: Symbol('modeldelete'),
    route: '/modeldelete',
    filePath: 'view/model/model-delete.vue',
    inNav: false,  //是否在左侧显示
    icon: 'iconfont icon-tushuguanli',
    order: 1,
  },
  {
    title: '大赛信息列表',
    type: 'view',
    name: Symbol('contest'),
    route: '/contest',
    filePath: 'view/contest/contest.vue',
    inNav: true,  //是否在左侧显示
    icon: 'iconfont icon-tushuguanli',
    order: 1,
  },
  {
    title: '添加大赛信息',
    type: 'view',
    name: Symbol('contest'),
    route: '/contestadd',
    filePath: 'view/contest/contest-add.vue',
    inNav: false,  //是否在左侧显示
    icon: 'iconfont icon-tushuguanli',
    order: 1,
  },
  {
    title: '参赛作品信息',
    type: 'view',
    name: Symbol('contestWork'),
    route: '/contestWork',
    filePath: 'view/contest/contestWork.vue',
    inNav: false,  //是否在左侧显示
    icon: 'iconfont icon-tushuguanli',
    order: 1,
  },
  {
    title: '404',
    type: 'view',
    name: Symbol('404'),
    route: '/404',
    filePath: 'view/error-page/404.vue',
    inNav: false,
    icon: 'iconfont icon-rizhiguanli',
  },
  // webBannerRouter,
  // webBannerRouter,
  // webQuestionRouter
  // printerRouter,
  // exploreRouter,
  // textRouter,
  // authRouter,
  // taskRouter,
  // sliceRouter,
  // personalRouter
  //bookConfig,
  //adminConfig,
]

// 接入插件
const plugins = [...pluginsConfig]
filterPlugin(homeRouter)
//homeRouter = homeRouter.concat(plugins)   //自定义组件和UI添加后在左侧显示

// 处理顺序
homeRouter = Utils.sortByOrder(homeRouter)
deepReduceName(homeRouter)
export default homeRouter

/**
 * 筛除已经被添加的插件
 */
function filterPlugin(data) {
  if (plugins.length === 0) {
    return
  }
  if (Array.isArray(data)) {
    data.forEach(item => {
      filterPlugin(item)
    })
  } else {
    const findResult = plugins.findIndex(item => data === item)
    if (findResult >= 0) {
      plugins.splice(findResult, 1)
    }
    if (data.children) {
      filterPlugin(data.children)
    }
  }
}

/**
 * 使用 Symbol 处理 name 字段, 保证唯一性
 */
function deepReduceName(target) {
  if (Array.isArray(target)) {
    target.forEach(item => {
      if (typeof item !== 'object') {
        return
      }
      deepReduceName(item)
    })
    return
  }
  if (typeof target === 'object') {
    if (typeof target.name !== 'symbol') {
      target.name = target.name || Utils.getRandomStr()
      target.name = Symbol(target.name)
    }

    if (Array.isArray(target.children)) {
      target.children.forEach(item => {
        if (typeof item !== 'object') {
          return
        }
        deepReduceName(item)
      })
    }
  }
}


