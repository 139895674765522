import appConfig from '@/config/index' // 引入项目配置
import stageConfig from '@/config/stage' // 引入舞台配置


console.log(appConfig.buckName)
console.log(appConfig.filesUrl)
console.log(appConfig.baseURL)
//  if(process.env.NODE_ENV=='development'){
 
//  }else{

//  }

export default {
  user: {}, // 当前用户
  loggedIn: false, // 是否登录
  permissions: [], // 每个用户的所有权限
  buckname:appConfig.Config.buckName,//window.customVariables.buckName,
  fileSvUrl:appConfig.Config.filesUrl,//window.customVariables.filesUrl,
  // 推送消息
  unreadMessages: [],
  alreadyReadMessages: [],
  language:'zh',

  // 舞台配置
  stageConfig,

  // 当前页信息
  currentRoute: {
    config: null,
    treePath: [],
  },

  sidebarLevel: appConfig.Config.sidebarLevel || 3,
  defaultRoute: appConfig.Config.defaultRoute || '/about',
}
