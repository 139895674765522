//const type='development' //production or development
const Config_CN=(val)=>{
return{
  baseURL:val==='zh'?process.env.VUE_APP_BASE_URL_CN:process.env.VUE_APP_BASE_URL_EN,
  buckName:val==='zh'?process.env.VUE_APP_buckName_CN:process.env.VUE_APP_buckName_EN,
  filesUrl:val==='zh'?process.env.VUE_APP_filesUrl_CN:process.env.VUE_APP_filesUrl_EN,//文件服务器路径
  domainName:val==='zh'?process.env.VUE_APP_domainName_CN:process.env.VUE_APP_domainName_EN ,//文件服务器域名
}
}
const Config = {
  sidebarLevel: 3, // 侧边栏层级（无限级）限制, 默认为 3 级，可根据需求自行扩充
  openAutoJumpOut: true, // 是否开启无操作跳出
  defaultRoute: '/', // 默认打开的路由
  websocketEnable: false, // 默认关闭 websocket
  showSidebarSearch: true, // 默认打开侧边栏搜索
  //notLoginRoute: ['login'], // 无需登录即可访问的路由 name,
  //notLoginRoute: ['about'], // 无需登录即可访问的路由 name,
  useFrontEndErrorMsg: false, // 默认采用后端返回异常
  stagnateTime: 60 * 60 * 1000, // 无操作停滞时间，默认1小时
  // baseURL: process.env.VUE_APP_BASE_URL, // API接口baseURL，在根目录.env文件查找对应环境变量配置
  //baseURL:window.customVariables.baseURL   //public config.js方式配置全局变量的方式更简单
  buckName:process.env.VUE_APP_buckName_CN,
  language:'zh',
  filesUrl:process.env.VUE_APP_filesUrl_CN,//文件服务器路径
  domainName:process.env.VUE_APP_domainName_CN //文件服务器域名
}
const Config_EN={
  buckName:process.env.VUE_APP_buckName_EN,
  filesUrl:process.env.VUE_APP_filesUrl_EN,
}

export default {
  Config_CN,
  Config,
  Config_EN
} 
